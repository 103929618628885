import i18n from '@/localisation/i18n';
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import { MarketTimeframes } from '@/modules/market-closed/types/market-closed';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import {
  AccountStatus,
  CompanyInfo,
  UserAccount,
} from '@/modules/user-accounts/types/user-accounts';
import { AuctionStatus } from '@/utils/helpers/buffers';
import { Role } from '@/utils/helpers/permissions';
import { format as formatDate, parseISO } from 'date-fns';
import Decimal from 'decimal.js';
import { extend } from 'lodash';
import { TradingPermission } from './trading-permissions';

// ORM actions
export enum RestOperation {
  Noop = 0,
  Create,
  Read,
  Update,
  Delete,
}

export enum AuctionMarket {
  SecuritiesLending = 'seclending',
  Swaps = 'swaps',
  Options = 'options',
  Combo = 'combo',
}

export enum AuctionStyle {
  ABA = 'aba',
}

// for the backend: buy=bid=loan; sell=ask=borrow
export enum Direction {
  Lend = 'lend',
  Borrow = 'borrow',
}

export enum OrderType {
  Limit = 'LIMIT',
  Market = 'MARKET',
}

// REST buffers
export interface ClientConfig {
  systemTitleShort: string;
  systemTitleLong: string;
  systemBrokerName: string;
  systemBrokerFullName: string;
  systemBrokerLegalName: string;
  systemBrokerSiteURL: string;
  systemBrokerContactEmail: string;
  systemProdLike: boolean;
  systemEnv: 'prod' | 'uat' | 'sandbox' | 'review' | 'demo' | 'test' | 'dev' | 'playground';
  systemRelease: string;
  systemRootURL: string;
  frontendHash: string;
  timezone: string;
  timezoneOffset: string;
  auctionMarketTimeIncrements: string;
  auctionMarketTimeMinOpen: string;
  auctionMarketDaysMaxOpen: number;
  auctionMarketAllowDuplicates: boolean;
  auctionMarketFatFingerMargin: number;
  auctionMarketBaseCurrency: string;
  tfaResetByEmail: boolean;
  maxRate: number;
  sentryServerDSN: string;
  sentryEnvironment: string;
  demoMode: boolean;

  /**
   * Enables/disabled the use of market timeframes to allow interaction with various features
   */
  marketTimeframesEnabled: boolean;
  marketTimeframes: MarketTimeframes;
  currentTimeUTC: Date;
  holidaysLast12Months: string[]; // yyyy-MM-dd

  // feature flags
  createAuctionsEnabled: boolean;
  executeAuctionsEnabled: boolean;
  createLoansEnabled: boolean;
  varCalcEnabled: boolean;
  orderbookCounterpartyChoiceEnabled: boolean;
  orderbookSettlementTypeChoiceEnabled: boolean;
  orderbookMinQuantityEditable: boolean;
  seclendingAnalyticsEnabled: boolean;
  termLoansEnabled: boolean;
  finraTransactionsEnabled: boolean;
  corpActionsEnabled: boolean;
  nsccDashboardEnabled: boolean;
  bilateralLoansEnabled: boolean;
  occBilateralLoansEnabled: boolean;
  orderManagerV2Enabled: boolean;
}

export interface UXConfig {
  hasGlobalSearch: boolean;
  hasSLAuction: boolean;
  // when forceMarketTimeframesCheckingEvenWhenDisabled is true we will enforce timeframes,
  //  even when they're disabled (for this env)
  forceMarketTimeframesCheckingEvenWhenDisabled: boolean;
  // when forceMarketTimeframesClosed is true we will pretend all timeframes are closed
  //  (so all timeframe dependant features will be disabled)
  forceMarketTimeframesClosed: boolean;
  dismissedPreEstablishedInfo: boolean;
}

export interface LoginSalt {
  salt: string;
}

export interface LoginResponse {
  user: MyAccount;
}

export interface LoginPreparePasswordResponse {
  email: string;
  freshAccount: boolean;
}

export interface MyAccount {
  id: string;
  name: string;
  emailAddress: string;
  companyID: string;
  companyName: string;
  companyDisplayBoxID: string | null;
  companyPreferredIndependentAmountRate: Decimal;
  companyPreferredRoundingRule: RoundingRule | null;
  companyTradingPermissions: TradingPermission;
  companyDefaultOmsCounterparties: CompanyInfo[];
  tradingPermissions: number | null; // trading permissions as defined in the database, could be limited by company permissions!
  roles: string[];
  tfaIsEnabled: boolean;
  accountStatus: AccountStatus;
  sponsorshipSide: 'sponsor' | 'sponsored' | null;

  orderSoftLimit: Decimal | null;
  defaultOrderSoftLimit: Decimal | null;
  orderHardLimit: Decimal | null;
  defaultOrderHardLimit: Decimal | null;

  // client request
  resetPassword: boolean;

  reportDeliveries: string[];
}

export function normalizeMyAccount(
  ua:
    | MyAccount
    | {
        companyPreferredIndependentAmountRate: string;
        orderSoftLimit: string;
        defaultOrderSoftLimit: string;
        orderHardLimit: string;
        defaultOrderHardLimit: string;
      }
): void {
  if (ua.companyPreferredIndependentAmountRate) {
    ua.companyPreferredIndependentAmountRate = new Decimal(
      ua.companyPreferredIndependentAmountRate as string
    );
  } else {
    ua.companyPreferredIndependentAmountRate = new Decimal(0);
  }

  // input from server will have soft-order-limit as a string, we convert it to Decimal
  const uaWithStrOrderSoftLimit: { orderSoftLimit: string | Decimal | null } = ua;
  if (typeof uaWithStrOrderSoftLimit.orderSoftLimit === 'string') {
    ua.orderSoftLimit = new Decimal(uaWithStrOrderSoftLimit.orderSoftLimit);
  }

  // input from server will have soft-order-limit as a string, we convert it to Decimal
  const uaWithStrDefaultOrderSoftLimit: { defaultOrderSoftLimit: string | Decimal | null } = ua;
  if (typeof uaWithStrDefaultOrderSoftLimit.defaultOrderSoftLimit === 'string') {
    ua.defaultOrderSoftLimit = new Decimal(uaWithStrDefaultOrderSoftLimit.defaultOrderSoftLimit);
  }

  // input from server will have hard-order-limit as a string, we convert it to Decimal
  const uaWithStrOrderHardLimit: { orderHardLimit: string | Decimal | null } = ua;
  if (typeof uaWithStrOrderHardLimit.orderHardLimit === 'string') {
    ua.orderHardLimit = new Decimal(uaWithStrOrderHardLimit.orderHardLimit);
  }

  // input from server will have hard-order-limit as a string, we convert it to Decimal
  const uaWithStrDefaultOrderHardLimit: { defaultOrderHardLimit: string | Decimal | null } = ua;
  if (typeof uaWithStrDefaultOrderHardLimit.defaultOrderHardLimit === 'string') {
    ua.defaultOrderHardLimit = new Decimal(uaWithStrDefaultOrderHardLimit.defaultOrderHardLimit);
  }
}

export interface User2FASettings {
  id: string;
  tfaIsEnabled: boolean;
  tfaIssuer: string;
  tfaIssuerName: string;
  tfaIssuerSecret: string;
}

export interface UserNotification {
  // [key: string]: UserNotification[keyof UserNotification]; // @TODO REMOVE ME

  id: string;
  userID: string;
  subject: string;
  message: string;
  createdAt: Date;
  readAt?: Date;

  category: string;
  type: string | null;
  fields: string | null;

  actionedAt: Date | null;

  // optional; only if notification is about an auction
  auctionID: string | null;
  auctionEvent: string | null;
  auctionEndsAt: Date | null;
  auctionExecutedAt: Date | null;
  equitySymbol: string | null;
  equityName: string | null;
}

export function normalizeUserNotification(
  un:
    | UserNotification
    | { createdAt: string; readAt: string; auctionEndsAt: string; auctionExecutedAt: string }
): void {
  // dates are passed as string in API response, but must be Date from here forward
  un.createdAt = parseISO(un.createdAt as string);
  if (un.readAt) {
    un.readAt = parseISO(un.readAt as string);
  }
  if (un.auctionEndsAt) {
    un.auctionEndsAt = parseISO(un.auctionEndsAt as string);
  }
  if (un.auctionExecutedAt) {
    un.auctionExecutedAt = parseISO(un.auctionExecutedAt as string);
  }
}

export interface RfcRequest {
  rfcText: string;
  rfcPath: string;
  rfcPage: string;
}

export interface CompanyAccountLite {
  id: string;
  name: string;
}

export interface TradingPermissionOption {
  permission: number | null; // null = inherit from company
}

export interface InvestorProfile {
  id: string;
  label: string;
}

// buffer behind the 'InitiateAuction' and 'OrderTicketDialog' dialogs
export interface AuctionDialogBuffer {
  equityID: string;
  equity: AuctionEquity | null;
  leakDirection: boolean;
  shareableDirection: Direction;
  leakQuantity: boolean;
  shareableQuantity: number;
  leakRate: boolean;
  shareableRate: Decimal;
  leakIsStackedOrder: boolean;
  auctionDay: string;
  auctionTime: string;
  participantList: string[];
  firstOrderTicket: OrderTicket;
}

export function createAuctionDialogBuffer(defaultParticipants: string[]): AuctionDialogBuffer {
  const order = createBlankOrder(0, new Decimal(0));
  const ticket = createOrderTicket('', '', Direction.Borrow, [order]);
  return {
    equityID: '',
    equity: null,
    leakDirection: true,
    shareableDirection: Direction.Borrow,
    leakQuantity: true,
    shareableQuantity: 0, // best volume in all order lines (matching best price)
    leakRate: true,
    shareableRate: new Decimal(0), // best price in all order lines
    leakIsStackedOrder: true,
    auctionDay: formatDate(new Date(), 'yyyy-MM-dd'),
    auctionTime: formatDate(new Date(), 'HH:mm'),
    participantList: defaultParticipants,
    firstOrderTicket: ticket,
  };
}

export interface BespokeAuction extends AuctionLeakage {
  // REST:
  id: string;
  equity: AuctionEquity;
  isOwnCompany: boolean;
  participants: string[];
  participantLabels: string[];
  endsAt: Date;
  executedAt: Date | null;

  isLeakedDirection: boolean;
  isLeakedQuantity: boolean;
  isLeakedRate: boolean;
  isLeakedIsStackedOrder: boolean;

  companyOrderTickets: OrderTicket[];

  // client buf:
  status: AuctionStatus;
  auctionID: string;
  equityID: string;
  securityName: string;
  originalDirection?: string | null;
  companyTotalVolume: number | null;
}

export function createBespokeAuction(
  auction: NonNullableAll<AuctionDialogBuffer>,
  endsAt: Date,
  orderTicket: OrderTicket
): BespokeAuction {
  return {
    id: '',
    equity: auction.equity,
    isOwnCompany: true,
    participants: auction.participantList,
    participantLabels: [],
    endsAt: endsAt,
    executedAt: null,

    isLeakedDirection: true,
    leakedDirection: auction.shareableDirection,
    isLeakedQuantity: true,
    leakedQuantity: auction.shareableQuantity,
    isLeakedRate: true,
    leakedRate: auction.shareableRate,
    isLeakedIsStackedOrder: true,
    leakedIsStackedOrder: auction.leakIsStackedOrder,

    companyOrderTickets: [orderTicket],

    status: AuctionStatus.Open,
    auctionID: '',
    equityID: auction.equity.id,
    securityName: auction.equity.name,

    originalDirection: null,
    companyTotalVolume: 0,
  };
}

export function normalizeBespokeAuction(a: BespokeAuction): void {
  // input from server will have rate a string, we convert it to Decimal
  const aWithStrRate: { leakedRate: string | Decimal | null } = a;
  if (typeof aWithStrRate.leakedRate === 'string') {
    a.leakedRate = new Decimal(aWithStrRate.leakedRate);
  }

  // endsAt is a string in API response, but Date from here forward
  a.endsAt = parseISO((a as unknown as { endsAt: string }).endsAt as string);

  if (a.executedAt) {
    // executedAt is a string in API response, but Date from here forward
    a.executedAt = parseISO(a.executedAt as unknown as string);
  }

  a.companyOrderTickets = a.companyOrderTickets.map((o) => normalizeOrderTicket(o));
}

export interface BespokeAuctionInput {
  // AuctionLeakage but with leakedRate as string
  leakedDirection: string | null;
  leakedQuantity: number | null;
  leakedRate: string | null;
  leakedIsStackedOrder: boolean | null;

  equityID: string;
  endsAt: Date;
  participants: string[];
  originalDirection?: string | null;
}

export function createBlankBespokeAuctionInput(): BespokeAuctionInput {
  return {
    equityID: '',
    endsAt: new Date(),
    participants: [],
    originalDirection: null,

    // I:AuctionLeakage
    leakedDirection: null,
    leakedQuantity: null,
    leakedRate: null,
    leakedIsStackedOrder: null,
  };
}

export function createBespokeAuctionInput(auction: BespokeAuction): BespokeAuctionInput {
  return extend({}, createBlankBespokeAuctionInput(), {
    equityID: auction.equityID,
    endsAt: auction.endsAt,
    participants: auction.participants,
    originalDirection: auction.originalDirection,

    // I:AuctionLeakage
    leakedDirection: auction.leakedDirection,
    leakedQuantity: auction.leakedQuantity,
    leakedRate: auction.leakedRate ? auction.leakedRate.toFixed(RATE_PRECISION) : null,
    leakedIsStackedOrder: auction.leakedIsStackedOrder,
  });
}

export interface ClosedAuction extends BespokeAuction {
  crossingRate: Decimal;
  companyOrderTickets: ClosedOrderTicket[];
}

export function normalizeClosedAuction(a: ClosedAuction): void {
  // input from server will have rate a string, we convert it to Decimal
  const aWithStrRate: { crossingRate: string | Decimal } = a;
  if (typeof aWithStrRate.crossingRate === 'string') {
    a.crossingRate = new Decimal(aWithStrRate.crossingRate);
  }

  normalizeBespokeAuction(a);
  a.companyTotalVolume = a.companyOrderTickets.reduce(
    (companyVolume, order) => companyVolume + order.totalQuantity,
    0
  );
}

export function auctionTotalFilledVolume(auction: ClosedAuction): number {
  return auction.companyOrderTickets.reduce((r, o) => {
    return r + (o.filledVolume || 0);
  }, 0);
}

export interface Order {
  quantity: number;
  rate: Decimal;

  filledVolume?: number;

  // html-table helpers
  rowID: number;
  quantityError: string;
  rateError: string;
}

export function normalizeOrder(o: Order): Order {
  // input from server will have rate a string, we convert it to Decimal
  const oWithStrRate: { rate: string | Decimal } = o;
  if (typeof oWithStrRate.rate === 'string') {
    o.rate = new Decimal(oWithStrRate.rate);
  }

  return o;
}

export function createBlankOrder(volume: number, rate: Decimal): Order {
  return {
    rowID: 0,
    quantity: volume,
    quantityError: '',
    rate: rate,
    rateError: '',
  };
}

export function copyOrder(order: Order): Order {
  return {
    quantity: order.quantity,
    rate: order.rate,

    rowID: 0,
    quantityError: '',
    rateError: '',
  };
}

export interface OrderTicket {
  // REST:
  id: string;
  auctionId: string;
  direction: Direction;
  orders: Order[];
  notes: string;
  checkedOffAt?: Date | null;

  // client buf:
  totalQuantity: number;
  avgRate: Decimal;
  renderedAt?: Date;
}

export interface StagedOrderTicket extends OrderTicket, AuctionLeakage {
  // StagedOrderTicket is only used as exchange buffer between AuctionOrderDialog.vue and AuctionOrderConfirmation.vue
  // The leakage fields are needed to update auction if the initial order is removed/replaced
}

export function normalizeOrderTicket(ticket: OrderTicket): OrderTicket {
  ticket.orders = ticket.orders.map(normalizeOrder);

  const totalQuantity = ticket.orders.reduce((sum, order) => sum + order.quantity, 0);
  const totalValue = ticket.orders.reduce((sum, order) => {
    return sum.add(order.rate.times(order.quantity));
  }, new Decimal(0));
  const avgRate = new Decimal(totalValue.dividedBy(totalQuantity).toFixed(RATE_PRECISION));

  return extend({}, ticket, {
    totalQuantity: totalQuantity,
    avgRate: avgRate,
    orders: ticket.orders.map((order, idx) => {
      return extend(createBlankOrder(0, new Decimal(0)), { rowID: idx }, order);
    }),
  });
}

export function createBlankOrderTicket(): OrderTicket {
  return {
    id: '',
    auctionId: '',
    direction: Direction.Borrow,
    notes: '',
    totalQuantity: 0,
    avgRate: new Decimal(0),

    orders: [createBlankOrder(0, new Decimal(0))],
  };
}

export function createOrderTicket(
  auctionID = '',
  orderID = '',
  direction: Direction = Direction.Borrow,
  orders?: Order[],
  notes = ''
): OrderTicket {
  if (orders === undefined) {
    orders = [createBlankOrder(0, new Decimal(0))];
  }

  return extend({}, createBlankOrderTicket(), {
    id: orderID,
    auctionId: auctionID,
    direction: direction,
    orders: orders,
    notes: notes,
  });
}

export function copyOrderTicket(ticket: OrderTicket, orders?: Order[]): OrderTicket {
  return extend({}, createBlankOrderTicket(), {
    id: ticket.id,
    auctionId: ticket.auctionId,
    direction: ticket.direction,
    notes: ticket.notes,
    orders: orders !== undefined ? orders : ticket.orders.map((order) => copyOrder(order)),
  });
}

export interface OrderInput {
  quantity: number;
  rate: string;
}

export interface OrderTicketInput extends AuctionLeakage {
  id: string;
  auctionId: string;
  direction: Direction;
  notes: string;
  orders: OrderInput[];
}

export function createBlankOrderTicketInput(): OrderTicketInput {
  return {
    id: '',
    auctionId: '',
    direction: Direction.Borrow,
    notes: '',
    orders: [{ quantity: 0, rate: '0' }],

    // I:AuctionLeakage
    leakedDirection: null,
    leakedQuantity: null,
    leakedRate: null,
    leakedIsStackedOrder: null,
  };
}

export function createOrderTicketInput(ticket: OrderTicket): OrderTicketInput {
  return extend({}, createBlankOrderTicketInput(), {
    id: ticket.id,
    auctionId: ticket.auctionId,
    direction: ticket.direction,
    notes: ticket.notes,
    orders: ticket.orders.map((o) => {
      return {
        quantity: o.quantity,
        rate: o.rate.toFixed(RATE_PRECISION),
      } as OrderInput;
    }),
  });
}

export interface AuctionLeakage {
  leakedDirection: string | null;
  leakedQuantity: number | null;
  leakedRate: Decimal | null;
  leakedIsStackedOrder: boolean | null;
}

/* @TODO REMOVE ME? Currently Unused
function normalizeAuctionLeakage(al: AuctionLeakage): AuctionLeakage {
  // input from server will have rate a string, we convert it to Decimal
  const alWithStrRate: { leakedRate: string | Decimal } = al as any;
  if (typeof alWithStrRate.leakedRate === 'string') {
    al.leakedRate = new Decimal(alWithStrRate.leakedRate);
  }

  return al;
}
*/

export function leakNothing(): AuctionLeakage {
  return {
    leakedDirection: null,
    leakedQuantity: null,
    leakedRate: null,
    leakedIsStackedOrder: null,
  };
}

interface StackableOrder extends Order {
  isStackedOrder: boolean;
}

export function getBestOrderFromTicket(
  auction: BespokeAuction,
  direction: string,
  companyOrderTickets: OrderTicket[]
): AuctionLeakage {
  const ret: AuctionLeakage = leakNothing();

  // filter on 'direction'
  const nestedOrders = companyOrderTickets
    .filter((order) => order.direction === direction)
    .map((o) =>
      o.orders.map(
        (order) => extend({}, order, { isStackedOrder: o.orders.length > 1 }) as StackableOrder
      )
    );

  // merge orders of all tickets into 1 array
  // abort if there are no orders with the right 'direction'?
  const auctionOrders: StackableOrder[] = ([] as StackableOrder[]).concat(...nestedOrders);
  if (auctionOrders.length === 0) {
    return ret;
  }

  // sort all orders to find the best on top
  const sortedOrders = auctionOrders.sort((ol1, ol2) => {
    if (direction === Direction.Lend) {
      return ol1.rate.comparedTo(ol2.rate);
    } else {
      return ol2.rate.comparedTo(ol1.rate);
    }
  });

  const bestOrder = sortedOrders[0];
  if (auction.isLeakedDirection) {
    ret.leakedDirection = direction;
  }
  if (auction.isLeakedQuantity) {
    ret.leakedQuantity = bestOrder.quantity;
  }
  if (auction.isLeakedRate) {
    ret.leakedRate = bestOrder.rate;
  }
  if (auction.isLeakedIsStackedOrder) {
    ret.leakedIsStackedOrder = bestOrder.isStackedOrder;
  }

  return ret;
}

export interface ClosedOrderTicket extends OrderTicket {
  filledVolume: number | null;
}

export interface AuctionEquity {
  id: string;
  cusip: string;
  ticker: string;
  statusCode: number;
  isinCode: string;
  name: string;
  closePrice: Decimal;
  isActive: boolean;
  isAuroraActive: boolean;
  isAuroraRestricted: boolean;
  hasActivePriceDataSource: boolean;
  isCusipActive: boolean;
  dtccCanNewLoan: boolean;
  dtccCanRoll: boolean;

  currencyCode: string;
}

export function normalizeAuctionEquity(eq: AuctionEquity): AuctionEquity {
  // input from server will have rate a string, we convert it to Decimal
  const eqWithStrPrice: { closePrice: string | Decimal } = eq;
  if (typeof eqWithStrPrice.closePrice === 'string') {
    eq.closePrice = new Decimal(eqWithStrPrice.closePrice);
  }

  return eq as AuctionEquity;
}

export interface NewsItem {
  key: string;
  title: string;
  value: Decimal;
  change: Decimal;
}

// @TODO: for now most as strings!
export interface WatchItem {
  status: string;
  security: string;
  ticker: string;
  interest: string;
  lastTrade: string;
  market: string;
  volume: number;
  rate: number;
  duration: string;
  auctionType: string;
}

export interface DesktopFeature {
  category: string;
  title: string;
  description: string;
  link: string;
  role: Role;
}

export function featureFactory(
  category: string,
  title: string,
  description: string,
  link: string,
  role: Role
): DesktopFeature {
  return {
    category: i18n.tc(category),
    title: i18n.tc(title),
    description: i18n.tc(description),
    link: link ? link : '',
    role: role,
  };
}

export interface UpdateTraderUserResponse {
  status: string;
  user: UserAccount;
  approvalRequired: boolean;
}
